.button_styles-module_button__2KbPc {
  border: none;
  background: transparent;
}

.button_styles-module_button__2KbPc:hover {
  background-color: #e2e8f0;
}

.button_styles-module_icon-button__2ddnV {
  padding: 0.5rem;
  border-radius: 9999px;
  color: var(--text-copy-primary);
}

.deepnotes-editor-theme-light {
  --bg-background-primary: white;
  --bg-background-secondary: #edf2f7;
  --bg-background-tertiary: #a0aec0;
  --bg-background-form: white;
  --text-copy-primary: #1a202c;
  --text-copy-secondary: #2d3748;
  --text-copy-tertiary: #718096;
  --border-border-color-primary: white;
}

.deepnotes-editor-theme-light .search-highlighted {
  background: #f0fff4;
}

.deepnotes-editor-theme-light .search-hover:hover {
  background: #f0fff4;
}

.deepnotes-editor-theme-dark .markdown-body {
  color: #24292e;
}

.deepnotes-editor-theme-dark .search-highlighted {
  background: #2d3748;
}

.deepnotes-editor-theme-dark .search-hover:hover {
  background: #2d3748;
}

.deepnotes-editor-theme-dark {
  --bg-background-primary: #232931;
  --bg-background-secondary: #393e46;
  --bg-background-tertiary: #4ecca3;
  --bg-background-form: #1a202c;
  --text-copy-primary: #f7fafc;
  --text-copy-secondary: #cbd5e0;
  --text-copy-tertiary: #a0aec0;
  --border-border-color-primary: #1a202c;
}

.deepnotes-editor-theme-dark .markdown-body {
  color: #cbd5e0;
}

.deepnotes-editor-theme-dark nav .active {
  border-bottom-width: 1px;
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
}

.editor_styles-module_container__3H-_W {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.5rem;
  padding-top: 0.75rem;
  padding-left: 1.5rem;
  margin-bottom: 3rem;
  border-radius: 0.375rem;
}

.editor_styles-module_new-item-button__3yUxz {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  flex-shrink: 0;
  padding: 0.5rem;
  margin-left: -0.75rem;
  border-radius: 9999px;
}

.editor_styles-module_new-item-button__3yUxz:hover {
  background-color: #e2e8f0;
}

.editor_styles-module_new-item-icon__3-ZVX {
  width: 0.75rem;
  height: 0.75rem;
  fill: currentColor;
}

.link_styles-module_link__3R12D {
  color: var(--text-copy-tertiary);
  text-decoration: underline;
  overflow-wrap: break-word;
  white-space: normal;
  cursor: pointer;
}

.link_styles-module_link__3R12D:visited {
  color: var(--text-copy-tertiary);
}

.link_styles-module_link__3R12D:hover {
  color: #97266d;
}

.menu_styles-module_menu-container__1H_0Q {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.75rem;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.menu_styles-module_menu-left-container__Ac7Ac {
  --space-x-reverse: 0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  margin-right: 1.5rem;
  color: #718096;
  margin-right: calc(0.5rem * var(--space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)));
}

.menu_styles-module_menu-right-container__2RbN3 {
  display: flex;
  align-items: center;
}

.menu_styles-module_menu-icon__jECU2 {
  width: 1rem;
  height: 1rem;
}

.menu_styles-module_menu-icon-large__2BwF4 {
  width: 1.25rem;
  height: 1.25rem;
}

.menu_styles-module_bookmarked__2CFZk {
  fill: currentColor;
  color: #d53f8c;
}

.menu_styles-module_not-bookmarked__1YiMp {
  stroke: currentColor;
}

[data-reach-dialog-overlay] {
  z-index: 100;
}

[data-reach-dialog-content] {
  position: relative;
}

[data-reach-dialog-content] > button.menu_styles-module_close-button__B-GJS {
  position: absolute;
  right: 10px;
  top: 0;
  padding: 5px;
  border: none;
  font-size: 150%;
}

@keyframes menu_styles-module_slide-down__1ZJ7b {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-reach-menu] {
  z-index: 100;
}

.menu_styles-module_slide-down__1ZJ7b[data-reach-menu-list] {
  border-radius: 5px;
  animation: menu_styles-module_slide-down__1ZJ7b 0.2s ease;
}

[data-reach-menu-item][data-selected] {
  background: #4a5568;
}

[data-reach-menu-button] {
  border: none;
}

[data-reach-dialog-content] {
  height: 80vh;
}

@media only screen and (max-width: 600px) {
  [data-reach-dialog-content] {
    width: 80vw;
    height: 80vh;
  }

  [data-reach-menu-item] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}


.item_styles-module_item-base__3sOp0 {
  display: flex;
  align-items: center;
  position: relative;
}

.item_styles-module_completed___ObuM {
  color: #a0aec0;
  text-decoration: line-through;
}

.item_styles-module_zoomed-in-item__18pC6 {
  padding-left: 0.25rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.5rem;
}

.item_styles-module_regular-item__23xsf {
  padding: 0.25rem;
}

.item_styles-module_small-text__u0K1d {
  font-size: 0.875rem;
}

.item_styles-module_item-container__3B3Jx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 0.75rem;
}

.item_styles-module_mobile-collapse-button__3cd1_ {
  display: none;
}

.item_styles-module_depth-manager__1pfhr {
  width: 100%;
  padding-left: 2rem;
  border-left-width: 1px;
  border-color: var(--bg-background-secondary);
  --border-opacity: 0.5;
}

@media (max-width: 640px) {
  .item_styles-module_mobile-collapse-button__3cd1_ {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.75rem;
  }
}

.disc_styles-module_disc-container__3t72L {
  position: relative;
  display: flex;
  align-items: center;
}

.disc_styles-module_collapse-button__116tC {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 1.5rem;
  margin-left: -2rem;
  border: none;
  opacity: 0;
  cursor: pointer;
}

.disc_styles-module_collapse-button__116tC:hover {
  opacity: 1;
}

.disc_styles-module_down-arrow-icon__2wGmr {
  transition-property: transform;
  transition-duration: 100ms;
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  fill: currentColor;
  color: var(--text-copy-primary);
}

.disc_styles-module_collapsed-down-arrow-icon__2c6c9 {
  --transform-rotate: -90deg;
}

.disc_styles-module_disc__MiMX- {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 9999px;
  cursor: pointer;
}

.disc_styles-module_disc__MiMX-:hover {
  background-color: var(--bg-background-tertiary);
}

.disc_styles-module_disc__MiMX-.disc_styles-module_disc-collapsed__2A4lQ {
  background-color: var(--bg-background-secondary);
}

.disc_styles-module_disc__MiMX-.disc_styles-module_disc-collapsed__2A4lQ:hover {
  background-color: var(--bg-background-tertiary);
}

.disc_styles-module_disc-icon__1QagH {
  fill: currentColor;
  color: var(--text-copy-secondary);
}
